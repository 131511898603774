<template>
  <DetailTemplate
    :customClass="'product-detail detail-page'"
    v-if="getPermission('sell:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mr-2"
          >
            {{ detail.name }}
          </h1>

          <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3"
            style="font-size: 21px !important; font-weight: 700"
            v-on:click="goToRoute(detailAll.product_id, detailAll.product_type)"
            >{{ detail.barcode }}</v-chip
          >
          <v-chip
            v-if="detail.activated == 1"
            class="mx-2"
            color="#66bb6a"
            label
            outlined
            text-color="green"
          >
            Active
          </v-chip>
          <v-chip
            v-if="detail.activated == 0"
            class="mx-2"
            color="red"
            outlined
            text-color="red"
            label
          >
            In-Active
          </v-chip>

          <v-chip
            label
            color="#0d47a1"
            v-if="detail.type == 'cross-rent'"
            outlined
            class="p-3 mr-3"
          >
            CROSS RENT
          </v-chip>

          <v-chip
            label
            color="green"
            class="text-white p-3 mr-2 mb-1"
            v-if="detail.is_sub_rental_exist"
          >
            Sub Rent
          </v-chip>
        </template>
      </div>
      <div>
        <v-row>
          <v-col md="2">
            <v-img
              :lazy-src="$defaultImage"
              :src="getImage()"
              aspect-ratio="1"
              max-height="150"
              max-width="150"
              class="custom-grey-border custom-border-radius-50"
              transition="fade-transition"
            >
            </v-img>
          </v-col>
          <v-col md="6">
            <v-row>
              <v-col md="12">
                <span
                  v-on:click="routeToCustomer()"
                  class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
                  >{{
                    detailAll?.rental_info?.customer_relation?.display_name
                  }}</span
                >
              </v-col>
              <v-col md="6">
                <label class="my-0 font-weight-600" style="font-size: 16px"
                  >Billing Location</label
                >
                <template v-if="detailAll?.rental_info?.billing_relation?.id">
                  <!-- <p class="mb-0">
                    {{ detailAll?.rental_info?.billing_relation?.property_address }}
                  </p> -->
                  <table width="100%">
                    <tr
                      v-if="
                        detailAll?.sale_info?.billing_relation?.property_name
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.sale_info?.billing_relation?.property_name
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.billing_relation?.street_1"
                    >
                      <td class="p-0">
                        {{ detailAll?.rental_info?.billing_relation?.street_1 }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.billing_relation?.street_2"
                    >
                      <td class="p-0">
                        {{ detailAll?.rental_info?.billing_relation?.street_2 }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.billing_relation?.unit_no"
                    >
                      <td class="p-0">
                        {{ detailAll?.rental_info?.billing_relation?.unit_no }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.billing_relation?.zip_code"
                    >
                      <td class="p-0">
                        {{ detailAll?.rental_info?.billing_relation?.zip_code }}
                      </td>
                    </tr>
                  </table>
                </template>
                <label class="mt-3 font-weight-600" style="font-size: 16px"
                  >Contact Details</label
                >
                <template
                  v-if="
                    detailAll?.rental_info?.billing_contact_person_relation?.id
                  "
                >
                  <!-- <p class="mb-0">
                    {{ detailAll?.rental_info?.billing_contact_person_relation?.display_name }}
                  </p>
                  <p class="mb-0">
                    {{
                      detailAll?.rental_info?.billing_contact_person_relation?.primary_phone
                    }}
                  </p>
                  <p class="mb-0 d-flex align-center">
                    {{
                      detailAll?.rental_info?.billing_contact_person_relation?.primary_email
                    }}
                  </p> -->
                  <table width="100%">
                    <tr
                      v-if="
                        detailAll?.rental_info?.billing_contact_person_relation
                          ?.display_name
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info
                            ?.billing_contact_person_relation?.display_name
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detailAll?.rental_info?.billing_contact_person_relation
                          ?.primary_phone
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info
                            ?.billing_contact_person_relation?.primary_phone
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detailAll?.rental_info?.billing_contact_person_relation
                          ?.primary_email
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info
                            ?.billing_contact_person_relation?.primary_email
                        }}
                      </td>
                    </tr>
                  </table>
                </template>
              </v-col>
              <v-col md="6">
                <label class="my-0 font-weight-600" style="font-size: 16px"
                  >Site Location</label
                >
                <template v-if="detailAll?.rental_info?.property_relation?.id">
                  <!-- <p class="mb-0">
                    {{ detailAll?.rental_info?.property_relation?.property_address }}
                  </p> -->
                  <table width="100%">
                    <tr
                      v-if="
                        detailAll?.sale_info?.property_relation?.property_name
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.sale_info?.property_relation?.property_name
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.property_relation?.street_1"
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info?.property_relation?.street_1
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.property_relation?.street_2"
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info?.property_relation?.street_2
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.property_relation?.unit_no"
                    >
                      <td class="p-0">
                        {{ detailAll?.rental_info?.property_relation?.unit_no }}
                      </td>
                    </tr>
                    <tr
                      v-if="detailAll?.rental_info?.property_relation?.zip_code"
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info?.property_relation?.zip_code
                        }}
                      </td>
                    </tr>
                  </table>
                </template>
                <label class="mt-3 font-weight-600" style="font-size: 16px"
                  >Contact Details</label
                >
                <template
                  v-if="
                    detailAll?.rental_info?.property_contact_person_relation?.id
                  "
                >
                  <!-- <p class="mb-0">
                    {{
                      detailAll?.rental_info?.property_contact_person_relation?.display_name
                    }}
                  </p>
                  <p class="mb-0">
                    {{
                      detailAll?.rental_info?.property_contact_person_relation?.primary_phone
                    }}
                  </p>
                  <p class="mb-0 d-flex align-center">
                    {{
                      detailAll?.rental_info?.property_contact_person_relation?.primary_email
                    }}
                    
                  </p> -->
                  <table width="100%">
                    <tr
                      v-if="
                        detailAll?.rental_info?.property_contact_person_relation
                          ?.display_name
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info
                            ?.property_contact_person_relation?.display_name
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detailAll?.rental_info?.property_contact_person_relation
                          ?.primary_phone
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info
                            ?.property_contact_person_relation?.primary_phone
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detailAll?.rental_info?.property_contact_person_relation
                          ?.primary_email
                      "
                    >
                      <td class="p-0">
                        {{
                          detailAll?.rental_info
                            ?.property_contact_person_relation?.primary_email
                        }}
                      </td>
                    </tr>
                  </table>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <div>
              <h4>Rental Info</h4>
              <div>
                <span
                  class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
                >
                  {{ detailAll?.sell_title }}
                </span>
                <Barcode
                  route="rental.detail"
                  :id="detailAll.rental_id"
                  :barcode="detailAll.sell_barcode"
                  class=""
                ></Barcode>
              </div>
              <div>
                <table width="100%">
                  <tr>
                    <td class="font-weight-600 pl-0" width="35%">
                      Billed Invoice
                    </td>
                    <td class="font-weight-600 pl-0">
                      <template v-if="detailAll?.billed_invoice">
                        {{ detailAll?.billed_invoice }}
                      </template>
                      <template v-else>
                        <span class="text-muted"> no billed invoice </span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-600 pl-0" width="35%">Start Date</td>
                    <td class="font-weight-600 pl-0">
                      <template v-if="detailAll?.start_date">
                        {{ formatDate(detailAll?.start_date) }}
                      </template>
                      <template v-else>
                        <span class="text-muted"> no start date </span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-600 pl-0" width="35%">Cease Date</td>
                    <td class="font-weight-600 pl-0">
                      <template v-if="detailAll?.cease_date">
                        {{ formatDate(detailAll?.cease_date) }}
                      </template>
                      <template v-else>
                        <span class="text-muted">no cease date</span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-600 pl-0" width="35%">Rental by</td>
                    <td class="font-weight-600 pl-0">
                      <template v-if="detailAll?.sale_by_name">
                        {{ detailAll?.sale_by_name }}
                      </template>
                      <template v-else>
                        <span class="text-muted">no rental by</span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-600 pl-0" width="35%">Item Type</td>
                    <td class="font-weight-600 pl-0">
                      <template v-if="detailAll?.product_type">
                        <v-chip
                          :color="
                            detailAll?.product_type == 'equipment'
                              ? 'blue white--text'
                              : 'green white--text'
                          "
                          label
                          v-if="detailAll.product_type"
                          small
                          class=""
                        >
                          <span class="font-size-14 font-weight-500">
                            {{
                              detailAll.product_type == "equipment"
                                ? "Equipment"
                                : "Spare Part"
                            }}
                          </span>
                        </v-chip>
                      </template>
                      <template v-else>
                        <span class="text-muted"
                          ><span class="text-muted"> no item type</span></span
                        >
                      </template>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('product:update') && false">
        <v-skeleton-loader
          class="custom-skeleton d-inline-block"
          v-if="pageLoading"
          type="button"
        >
        </v-skeleton-loader>
        <v-menu
          v-else
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in productMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-if="getPermission('product:update') && false">
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :to="
            getDefaultRoute('product.update', {
              param: { id: product },
            })
          "
        >
          <v-icon small left>mdi-pencil</v-icon>
          Edit
        </v-btn>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-tabs
          v-model="productTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              v-if="
                getPermission(tab.permission + ':view') &&
                tab.conditional &&
                tab.key == 'sub-rent' &&
                detail.is_sub_rental_exist
              "
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
            </v-tab>

            <v-tab
              v-if="getPermission(tab.permission + ':view') && !tab.conditional"
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
              <span v-if="tab.title == 'Supplier'">
                ({{
                  detail.product_supplier ? detail.product_supplier.length : 0
                }})
              </span>
              <span v-if="tab.title == 'Pricing'">
                ({{
                  detail.product_pricelogs
                    ? detail.product_pricelogs.length
                    : 0
                }})
              </span>
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="productTab">
          <v-tab-item v-if="getPermission('product:view')" :value="'product'">
            <Overview
              :detail="detail"
              :p-type="detailAll?.product_type"
              :allow-purchaseinfo="allowPurchaseinfo"
            ></Overview>
          </v-tab-item>

          <v-tab-item v-if="getPermission('product:view')" :value="'sub-rent'">
            <SubRentalItemsEquipment
              :equipment-id="detail?.product?.id"
              :detail="detail"
              :rental-item="detailAll.id"
              type="equipment"
              module="rental-line-item"
            >
            </SubRentalItemsEquipment>
          </v-tab-item>

          <v-tab-item v-if="getPermission('product:view')" value="supplier">
            <ProductSpplierListing
              type="supplier"
              :product="detail.id"
              v-if="detail.id"
            ></ProductSpplierListing>
          </v-tab-item>

          <v-tab-item v-if="getPermission('product:view')" value="pricing">
            <ProductPriceListing
              type="pricing"
              :price-data="detail.product_pricelogs"
              :product="detail.id"
              @success="getProductDetail()"
            ></ProductPriceListing>
          </v-tab-item>
          <v-tab-item v-if="getPermission('product:view')" :value="'image'">
            <div class="service-detail-height">
              <Images
                :images="detail.product_images"
                :parent="detail.id"
                type="product"
                :isPageLoading="pageLoading"
              ></Images>
            </div>
          </v-tab-item>

          <v-tab-item
            v-if="getPermission('product:view')"
            :value="'attachments'"
          >
            <div class="service-detail-height">
              <FileTemplate
                :attachments="detail.product_attachments"
              ></FileTemplate>
            </div>
          </v-tab-item>
          <v-tab-item v-if="getPermission('warranty:view')" :value="'warranty'">
            <div class="service-detail-search-height">
              <Warranty :detail="detail"></Warranty>
            </div>
          </v-tab-item>
          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <div class="service-detail-search-height">
              <Tickets :detail="detail" type="product"></Tickets>
            </div>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('quotation:view')"
            :value="'quotation'"
          >
            <div class="service-detail-search-height">
              <Quotations :detail="detail" type="product"></Quotations>
            </div>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <div class="service-detail-search-height">
              <Invoices :detail="detail" type="product"></Invoices>
            </div>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('product-adjustment:view')"
            :value="'product-adjustment'"
          >
            <div class="service-detail-search-height">
              <ProductAdjustment :detail="detail"></ProductAdjustment>
            </div>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <div class="service-detail-search-height">
              <InternalHistoryDetail
                type="product"
                :type_id="detail.id"
              ></InternalHistoryDetail>
            </div>
          </v-tab-item>
          <v-tab-item value="attachment">
            <AdditionalDocuments
              :type-id="detail.id"
              class="mx-4"
              type-text="Product Documents"
              :type="9"
              :extensive="false"
            ></AdditionalDocuments>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </DetailTemplate>
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/rental-items/detail/Overview";
import ProductPriceListing from "@/view/pages/sold-items/detail/ProductPriceListing";
import ProductSpplierListing from "@/view/pages/sold-items/detail/ProductSpplierListing";
import Warranty from "@/view/pages/sold-items/detail/Warranty";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import ProductAdjustment from "@/view/pages/sold-items/detail/ProductAdjustment";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import CommonMixin from "@/core/plugins/common-mixin";
import SubRentalItemsEquipment from "@/view/pages/partials/Detail/SubRentalItemsEquipment";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      allowPurchaseinfo:0,
      detailAll: new Object(),
      detail: new Object(),
      productTab: "product",
      productMoreAction: [
        /*{
          title: "Convert to Equipment",
          action: "convert_to_equipment",
          icon: "mdi-power-plug-outline",
          disabled: false,
        },*/
        {
          title: "Duplicate",
          action: "duplicate",
          icon: "mdi-content-duplicate",
          disabled: false,
        },
        // {
        //   title: "Generate Adjustment",
        //   action: "generate_adjustment",
        //   icon: "mdi-content-duplicate",
        //   disabled: false,
        // },
        {
          title: "Mark as Active",
          action: "mark_as_active",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as In-Active",
          action: "mark_as_inactive",
          icon: "mdi-check-all",
          disabled: false,
        },
      ],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "product",
          permission: "product",
          disabled: false,
          conditional: false,
        },
        {
          title: "Sub Rents",
          icon: "mdi-phone",
          key: "sub-rent",
          permission: "product",
          disabled: false,
          conditional: true,
        },
        /*{
          title: "Adjustment",
          icon: "mdi-account-multiple",
          key: "product-adjustment",
          permission: "product-adjustment",
          disabled: false,
        },*/
        /*{
          title: "Supplier",
          icon: "mdi-phone",
          key: "supplier",
          permission: "product",
          disabled: false,
        },
        {
          title: "Pricing",
          icon: "mdi-phone",
          key: "pricing",
          permission: "product",
          disabled: false,
        },
        {
          title: "Images",
          icon: "mdi-phone",
          key: "image",
          permission: "product",
          disabled: false,
        },

        {
          title: "Attachments",
          icon: "mdi-phone",
          key: "attachment",
          permission: "product",
          disabled: false,
        },*/
        /* {
          title: "Warranty",
          icon: "mdi-credit-card",
          key: "warranty",
          permission: "warranty",
          disabled: false,
        }, */

        /*{
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          permission: "job",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          permission: "quotation",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          permission: "invoice",
          disabled: false,
        },*/
        /*{
          title: "History",
          icon: "mdi-account-multiple",
          key: "history",
          permission: "history",
          disabled: false,
        },*/
      ],
      formValid: true,
      formLoading: false,
      pageLoading: true,
      //productTab: null,
      product: 0,
    };
  },
  components: {
    Barcode,
    DetailTemplate,
    SubRentalItemsEquipment,
    Overview,
    ProductPriceListing,
    ProductSpplierListing,
    Warranty,
    Tickets,
    Images,
    FileTemplate,
    Quotations,
    Invoices,
    ProductAdjustment,
    InternalHistoryDetail,
    AdditionalDocuments,
  },
  methods: {
    getUserDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "current-user",
        })
        .then(({ data }) => {
          _this.allowPurchaseinfo = data?.is_manager;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getImage() {
      if (this.detailAll.product_type == "goods") {
        return this.detail?.product_image?.file?.url;
      }
      if (this.detailAll.product_type == "equipment") {
        return this.detail.image;
      }
      return this.$defaultImage;
    },
    goToRoute(id = 0, type = "") {
      //console.log(id,type)
      if (!id && !type) {
        return false;
      } else {
        var path = "";
        if (type == "goods") {
          path = "product.detail";
        }
        if (type == "equipment") {
          path = "equipment.detail";
        }
        this.$router.push(
          this.getDefaultRoute(path, {
            params: { id },
          })
        );
      }
    },
    patchProductParams(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: [_this.product],
          },
        })
        .then(() => {
          _this.getProductDetail();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "convert_to_equipment":
          _this.convertToEquipment();
          break;
        case "duplicate":
          _this.duplicateProduct();
          break;
        case "generate_adjustment":
          _this.adjustmentProduct();
          break;
        case "mark_as_active":
          _this.patchProductParams(1);
          break;
        case "mark_as_inactive":
          _this.patchProductParams(0);
          break;
      }
    },
    convertToEquipment() {
      this.$router.push(
        this.getDefaultRoute("equipment.create", {
          query: {
            product: this.product,
          },
        })
      );
    },
    duplicateProduct() {
      this.$router.push(
        this.getDefaultRoute("product.create", {
          query: {
            duplicate: this.product,
          },
        })
      );
    },
    adjustmentProduct() {
      // alert(23);
      console.log(this.product, "hello");
      this.$router.push(
        this.getDefaultRoute("adjustment.create", {
          query: {
            generate_adjustment: this.product,
            product_type: "goods",
          },
        })
      );
    },
    getProductDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "rental-items/" + _this.product,
        })
        .then(({ data }) => {
          _this.detailAll = data;
          _this.detail = data.product;
          //console.log(_this.detail,"_this.detail");
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Rented Item", route: "rental-items" },
            { title: "Detail" },
            { barcode: data.product.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProductDetail();
    _this.getUserDetail();
    this.productTab = "product";

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Rental Item", route: "rental-items" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.product = _this.$route.params.id;
    if (!_this.product || _this.product <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.productTab = _this.$route.query.tab;
    }
  },
  computed: {
    // productTab: {
    //   set(val) {
    //     let query = { ...this.$route.query };
    //     query.tab = val;
    //     if (this.productTab != val) {
    //       this.$router.push({ query });
    //     }
    //   },
    //   get() {
    //     return this.$route.query.tab || "product";
    //   },
    // },
  },
};
</script>
<style>
#attachment .overflow-y {
  max-height: none !important;
}
</style>
