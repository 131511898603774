<template>
  <Dialog :commonDialog="documentDialog" :dialog-width="1100">
    <template v-slot:title> Add Sub-Rent</template>
    <template v-slot:body>
      <v-form
        ref="documentForm"
        v-model.trim="export_valid"
        lazy-validation
        v-on:submit.stop.prevent="submitDocument()"
      >
        <v-card-text class="p-6 font-size-16">
          <template>
            <v-row
              v-for="(row, index) in sub_rents"
              :key="`prev-subrent-${index}`"
            >
              <v-col sm="12">
                <v-layout>
                  <v-flex class="mr-4" md4>
                    <label
                      for="month2"
                      class="font-size-16 font-weight-600 pl-4"
                      >Duration Type</label
                    >
                    <br />
                    <v-select
                      :items="durationTypes"
                      dense
                      filled
                      v-model.trim="row.duration_type"
                      solo
                      flat
                      class="pl-2 pr-2 width-100"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      v-on:change="(e) => UpdateEndDate(e, 'sub-rental', index)"
                    ></v-select>
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 required ml-2"
                      >Duration</label
                    >
                    <v-text-field
                      id="month"
                      type="number"
                      v-model.trim="row.month"
                      dense
                      filled
                      label="Month"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      :min="1"
                      oninput="if(this.value < 0) this.value = 0;"
                      v-on:keyup="(e) => UpdateEndDate(e, 'sub-rental', index)"
                      :rules="[
                        validateRules.required(row.month, ' Sub Rent Month'),
                      ]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 required ml-2"
                      >Start Date</label
                    >
                   
                    <date-picker
                      key="start-date1"
                      id="start-date1"
                      :min-date="startDateValidation.min"
                      :max-date="startDateValidation.max"
                      :show-current="startDateValidation.min"
                      v-model="row.start_date"
                      v-on:change="(e) => UpdateEndDate(e, 'sub-rental', index)"
                      :rules="[
                        validateRules.required(
                          row.start_date,
                          ' Sub Rent Start Date'
                        ),
                      ]"
                    />
                  </v-flex>
                </v-layout>

                <v-layout class="mt-2">
                  <v-flex class="mr-4" md4>
                    <label
                      class="font-size-16 font-weight-600 ml-2 pl-4 required"
                      >Off-Hire</label
                    ><br />
<!-- 
                    <template v-if="row?.off_hire">
                      <span class="black--text pl-4">{{
                        formatDate(row?.off_hire)
                      }}</span>
                    </template>
                    <template v-else>
                      <em class="pl-4">no off hire</em>
                    </template> -->

                    <date-picker
                      v-if="true"
                      key="off-hire"
                      id="off-hire"
                      :min-date="row.start_date"
                      v-model="row.off_hire"
                      :max-date="endDateValidation.max"
                      :show-current="endDateValidation.max"
                      :rules="[
                        validateRules.required(
                          row.start_date,
                          ' Sub Rent End Date'
                        ),
                      ]"
                    />
                  </v-flex>

                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 ml-2"
                      >Customer Name</label
                    >
                    <v-text-field
                      class="pr-2 width-100"
                      id="sub_rent_customer_name"
                      dense
                      filled
                      v-model="row.customer_name"
                      hide-details
                      placeholder="Customer Name"
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 ml-2"
                      >Inv No.
                    </label>
                    <v-text-field
                      class="pr-2 width-100"
                      id="sub_rent_invoice_no"
                      dense
                      filled
                      v-model="row.invoice_no"
                      hide-details
                      placeholder="Inv No."
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </v-flex>
                </v-layout>
                <v-layout class="mt-2">
                  <v-col
                    md="12"
                    class="gray-background"
                    style="border-bottom: 1px solid lightgray !important"
                  ></v-col>
                </v-layout>
                <v-layout class="mt-2">
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 required ml-2">
                      Site Location Name
                    </label>
                    <v-text-field
                      v-model.trim="row.site_location_name"
                      :rules="[
                        validateRules.maxLength(
                          row.site_location_name,
                          'Site Location Name',
                          100
                        ),
                        validateRules.required(
                          row.site_location_name,
                          'Site Location Name'
                        ),
                      ]"
                      dense
                      filled
                      label="Site Location Name."
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 ml-2"
                      >Country
                    </label>
                    <v-autocomplete
                      v-model.trim="row.country"
                      :items="countryList"
                      hide-details
                      flat
                      :rules="[validateRules.required(row.country, 'Country')]"
                      solo
                      dense
                      item-value="value"
                      item-text="text"
                      label="Country"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="countryCheck($event)"
                      class="pt-0 mt-0"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>

                    <!-- <v-text-field
                        v-model.trim="row.country"
                        :rules="[
                          
                          validateRules.maxLength(
                            row.country,
                            'Country',
                            10
                          ),
                        ]"
                        dense
                        filled
                        label="Country"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field> -->
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-500">
                      Search Address
                    </label>
                    <SearchAddress
                      variant="outlined"
                      density="compact"
                      hide-details
                      :country="row.country"
                      :country-code="country_code"
                      @update:address="(data) => setAddress(data)"
                      :label="
                        row.country == 'singapore'
                          ? 'Search Address'
                          : 'Search Address'
                      "
                      :placeholder="
                        row.country == 'singapore'
                          ? 'Search Address'
                          : 'Search Address'
                      "
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex class="mr-4" md4>
                    <label
                      class="font-size-16 font-weight-600 ml-2 required mt-2"
                      >Postal Code</label
                    >
                    <v-text-field
                      v-model.trim="row.postal_code"
                      :rules="[
                        validateRules.maxLength(
                          row.postal_code,
                          'Postal Code',
                          10
                        ),
                        validateRules.minLength(
                          row.postal_code,
                          'Postal Code',
                          2
                        ),
                        validateRules.required(row.postal_code, 'Postal Code'),
                      ]"
                      dense
                      filled
                      v-mask="'######'"
                      label="Postal Code"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 ml-2 mt-2"
                      >Unit No.</label
                    >
                    <v-text-field
                      class="pr-2 width-100"
                      id="unit_no"
                      dense
                      filled
                      v-model="row.unit_no"
                      hide-details
                      placeholder="Unit No."
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </v-flex>
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 ml-2 mt-2"
                      >Address Line 1
                    </label>
                    <v-text-field
                      class="pr-2 width-100"
                      id="address_line"
                      dense
                      filled
                      v-model="row.address_line_1"
                      hide-details
                      placeholder="Address Line 1"
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </v-flex>
                </v-layout>
                <v-layout class="mt-2">
                  <v-flex class="mr-4" md4>
                    <label class="font-size-16 font-weight-600 ml-2"
                      >Address Line 2
                    </label>
                    <v-text-field
                      class="pr-2 width-100"
                      id="address_line2"
                      dense
                      filled
                      v-model="row.address_line_2"
                      hide-details
                      placeholder="Address Line 2"
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </v-flex>
                </v-layout>

                <v-layout class="mt-2">
                  <v-flex class="mr-4" md8>
                    <label class="font-size-16 font-weight-600 ml-2">
                      Remark
                    </label>
                    <v-textarea
                      auto-grow
                      dense
                      filled
                      color="cyan ml-2"
                      v-model="row.remark"
                      placeholder="Remark"
                      solo
                      flat
                      row-height="25"
                      :maxlength="250"
                    />
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!export_valid || attachLoading"
        :loading="attachLoading"
        class="white--text"
        depressed
        color="cyan"
        tile
        v-on:click="submitDocument()"
      >
        Submit
      </v-btn>
      <v-btn
        depressed
        tile
        :disabled="attachLoading"
        :loading="attachLoading"
        v-on:click="$emit('close', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
//import Dialog from "@/view/components/Dialog.vue";
//import TextInput from "@/view/pages/TextInput";
//import TextAreaInput from "@/view/pages/TextAreaInput";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ApiService from "@/core/services/api.service";
// import { SET_ERROR } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/QDatePicker.vue";
import MomentJS from "moment-timezone";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
// import {GET } from "@/core/services/store/request.module";
import SearchAddress from "@/view/components/Search-Address";

export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "export-dialog",
  data() {
    return {
      country_code: "sg",
      fileIds: [],
      export_valid: true,
      attachLoading: false,
      files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      customer: null,
      currentDate: MomentJS().format("YYYY-MM-DD"),
      search: null,
      items: [],
      pageLoading: false,

      sub_rents: [
        {
          customer_name: null,
          start_date: null,
          off_hire: null,
          invoice_no: null,
          remark: null,
          month: 1,
          site_location_name: null,
          unit_no: null,
          address_line_1: null,
          address_line_2: null,
          postal_code: null,
          country: "singapore",
          duration_type: "day",
        },
      ],
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      durationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
    };
  },
  props: {
    // start-date-validation
    startDateValidation: {
      type: Object,
      default: null,
    },
    endDateValidation: {
      type: Object,
      default: null,
    },

    rental: {
      type: Object,
      default: null,
    },

    lastSubRent: {
      type: Object,
      default: null,
    },
    rentalItem: {
      type: Number,
      default: 0,
    },

    moduleType: {
      type: String,
      default: null,
    },
    documentDialog: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: Number,
      default: null,
    },
    documentId: {
      type: Number,
      default: null,
    },
    parentType: {
      type: String,
      default: null,
    },
    parentTypeId: {
      type: Number,
      default: null,
    },
    extensive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    documentDialog(param) {
      this.files = [];
      if (param) {
        this.customer = null;
        this.files = [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ];
      }
    },
  },
  methods: {
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });
      this.country_code = code.country_code;

      this.sub_rents[0].postal_code = null;
      this.sub_rents[0].address_line_1 = null;
      this.sub_rents[0].address_line_2 = null;
      this.sub_rents[0].unit_no = null;
      //  this.propertyAddress.longitude = null;
      //this.propertyAddress.latitude = null;
    },
    setAddress(e) {
      this.sub_rents[0].postal_code = e.postal_code;
      this.sub_rents[0].address_line_1 = e.address1;
      this.sub_rents[0].address_line_2 = e.address2;
      this.sub_rents[0].unit_no = e.unit_number;
      // this.row.longitude = e.longitude;
      // this.row.latitude = e.latitude;
    },
    UpdateEndDate(value = 0, type = "", index = 0) {
     
      if (!value) return false;

      let startDate = "";
      let duration = 1;
      let duration_type = "month";

      duration = this.sub_rents[index].month;
      startDate = this.sub_rents[index].start_date;
      duration_type = this.sub_rents[index]?.duration_type;

      if (startDate) {
        // let date = MomentJS(startDate, "YYYY-MM-DD").add(
        //   duration,
        //         "M"
        //       );

        let date = "";
        switch (duration_type) {
          case "day":
            date = MomentJS(startDate, "YYYY-MM-DD").add(duration, "d");
            break;
          case "month":
            date = MomentJS(startDate, "YYYY-MM-DD").add(duration, "M");
            break;
          case "year":
            date = MomentJS(startDate, "YYYY-MM-DD").add(duration, "y");
            break;
        }
        
        if (date) {
          date = date.format("YYYY-MM-DD");
        }
        
        if (this.endDateValidation.max) {
          if (date > this.endDateValidation.max) {
            this.sub_rents[index].month = 1;
            //this.$store.commit(SET_ERROR, [{ model: true, message: "Sub rent is exceeding, please re-select dates." }]);
            return false;
          }
        }
        
        if (type == "sub-rental") {
          this.sub_rents[index].off_hire = date;
        }

       
      }
    },

    checkTag(e, index) {
      console.log(e);
      if (e.key == "Enter") {
        this.files[index].tags = this.files[index].tags.map((v) => {
          if (typeof v === "string") {
            v = {
              tag: v,
            };
            this.items.push(v);
          }

          return v;
        });
      }
    },
    /* manageLimit(e, index) {
      if (this.files[index].remark && this.files[index].remark.length > 199) {
        e.preventDefault();
      }
    }, */

    manageLimit(e, index) {
      if (this.files[index].remark && this.files[index].remark.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e, index) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.files[index].remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.files[index].remark = trimValue;
        e.preventDefault();
      }
    },

    addMore() {
      this.files.push({
        file: null,
        name: null,
        remark: null,
        suffix: null,
      });
    },
    submitDocument() {
      const _this = this;

      // const formErrors = _this.validateForm(_this.$refs.documentForm);

      if (!_this.$refs.documentForm.validate()) {
        return false;
      }

      // if (formErrors.length) {
      // 	_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
      // 	return false;
      // }

      // if (!_this.$refs.documentForm.validate()) {
      //   return false;
      // }

      const params = new FormData();

      /*
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file && this.files[i].name) {
          params.append(`file[${i}][file]`, this.files[i].file);
          params.append(`file[${i}][name]`, this.files[i].name);
          if (this.files[i].remark) {
            params.append(`file[${i}][remark]`, this.files[i].remark);
          }
          if (this.files[i].start_date && this.files[i].end_date) {
            params.append(`file[${i}][start_date]`, this.files[i].start_date);
            params.append(`file[${i}][end_date]`, this.files[i].end_date);
          }
          if (this.files[i].reminder_date) {
            params.append(
              `file[${i}][reminder_date]`,
              this.files[i].reminder_date
            );
          }
          if (this.files[i].tags && this.files[i].tags.length > 0) {
            for (let t = 0; t < this.files[i].tags.length; t++) {
              if (this.files[i].tags[t].tag) {
                params.append(
                  `file[${i}][tags][${t}]`,
                  this.files[i].tags[t].tag
                );
              }
            }
          }
        }
      } */

      // params.append("type_id", this.documentId);
      // params.append("type", this.documentType);
      //params.append("module_type", this.moduleType);

      // if (this.parentType && this.parentTypeId && this.parentTypeId > 0) {
      params.append("rental_line_item", this.rentalItem);
      for (let i = 0; i < this.sub_rents.length; i++) {
        if (this.sub_rents[i].month && this.sub_rents[i].start_date) {
          params.append(`sub_rent[${i}][month]`, this.sub_rents[i].month);
          params.append(
            `sub_rent[${i}][start_date]`,
            this.sub_rents[i].start_date
          );
          params.append(`sub_rent[${i}][off_hire]`, this.sub_rents[i].off_hire);
          params.append(
            `sub_rent[${i}][invoice_no]`,
            this.sub_rents[i].invoice_no
          );
          params.append(
            `sub_rent[${i}][customer_name]`,
            this.sub_rents[i].customer_name
          );
          params.append(`sub_rent[${i}][remark]`, this.sub_rents[i].remark);
          params.append(
            `sub_rent[${i}][site_location_name]`,
            this.sub_rents[i].site_location_name
          );
          params.append(`sub_rent[${i}][unit_no]`, this.sub_rents[i].unit_no);
          params.append(
            `sub_rent[${i}][address_line_1]`,
            this.sub_rents[i].address_line_1
          );
          params.append(
            `sub_rent[${i}][address_line_2]`,
            this.sub_rents[i].address_line_2
          );
          params.append(
            `sub_rent[${i}][postal_code]`,
            this.sub_rents[i].postal_code
          );
          params.append(`sub_rent[${i}][country]`, this.sub_rents[i].country);
        }
      }

      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.post("sub-rent", params)
        .then((data) => {
          if (data) {
            this.fileIds = data.data.data;
            _this.$emit("getUploadedIds", this.fileIds);
            //reset array
            this.sub_rents = [
              {
                customer_name: null,
                start_date: null,
                off_hire: null,
                invoice_no: null,
                remark: null,
                month: 1,
                site_location_name: null,
                unit_no: null,
                address_line_1: null,
                address_line_2: null,
                postal_code: null,
                country: "singapore",
                duration_type: "month",
              },
            ];
          }
          this.$emit("success", true);
          this.$emit("close", true);
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },

    updateFile(index, file) {
      if (file && file.name) {
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
    getTags() {
      const _this = this;
      ApiService.setHeader();
      ApiService.get("setting/all-tags").then((res) => {
        _this.items = res.data && res.data.data ? res.data.data : [];
      });
    },
    filterTag(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  components: {
    Dialog,
    SearchAddress,
    "date-picker": DatePicker,
    //TextInput,
    //AutoCompleteInput,
    //TextAreaInput,
    // DatePicker,
  },
  mounted() {
    //this.getTags();
    //this.get_options();
    //console.log(this.moduleType);
  },
  computed: {
    ...mapGetters(["errors", "localDB"]),
  },
};
</script>
<style scoped>
/* .v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
} */

/* .v-input input {
  max-height: 30px;
} */
.removeShadow > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  /*  max-height: 18px; */
  padding: 0;
}
.v-application .py-3 {
  /* padding-top: 12px !important; */
  padding-bottom: 0px !important;
}
.text-right.col-md-12.col {
  margin: -10px;
}
</style>
